<template>
    <div class="audio-demo-player">
        <div class="demo-list">
            <div v-for="audioDemo in audioDemos" v-bind:key="audioDemo.id">
                <div class="demo-item" :class="(audioDemo.audio_file == currentAudioDemo) ? 'active': ''" v-on:click="loadDemo(audioDemo.audio_file)">
                    <div class="demo-title">{{ audioDemo.title }}</div>
                    <div class="demo-length">{{ audioDemo.audio_length }}</div>
                </div>
            </div>
        </div>
        <div class="controls" :class="isAudioDemoPlaying ? 'audio-active': ''">
            <div class="button" v-on:click="toggleDemo();" :class="isAudioDemoPlaying ? 'active' : ''">
                <i class="mdi mdi-play-pause"></i>
            </div>
            <div class="button" :class="!isAudioDemoPlaying ? 'disabled': ''" v-on:click="downloadDemo()">
                <i class="mdi mdi-download"></i>
            </div>

            <input type="range" min="0" :max="audioLength" v-model="audioProgress" v-on:input="changeDemoPosition()" class="demo-progress-slider" :class="!isAudioDemoPlaying ? 'audio-inactive': ''" />
            <input type="range" min="0" max="100" v-model="audioDemoVolume" v-on:input="changeDemoVolume()" class="demo-volume-slider" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';

    export default {
        name: 'AudioDemoPlayer',
        data: function() {
            return {
                vfapi: null,
                audioDemos: [],
                currentAudioDemo: 0,
                audioObject: null,
                audioURL: null,
                audioDemoVolume: 100,
                audioProgress: 0,
                audioLength: 0,
                audioInterval: null,
                isAudioDemoPlaying: false,
            }
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();
            this.$data.audioObject = new Audio();
            this.$data.audioObject.onended = () => { this.endDemo(); }

            this.$data.vfapi.getItems('audio_demo').then(data => {
                this.$data.audioDemos = data;
                this.loadDemo(data[0].audio_file);
            });
        },
        methods: {
            loadDemo: function(audioFileID) {
                if(!this.$data.audioObject.paused) {
                    this.endDemo();
                }

                this.$data.currentAudioDemo = audioFileID;

                this.$data.vfapi.getFileUrl(audioFileID).then(data => {
                    this.$data.audioObject.src = data;
                    this.$data.audioURL = data;
                });
            },
            toggleDemo: function() {
                if(this.$data.audioObject.paused) {
                    this.startDemo();
                } else {
                    this.endDemo();
                }
            },
            downloadDemo: function() {
                if(this.isAudioDemoPlaying) {
                    window.open(this.$data.audioURL);
                }
            },
            changeDemoVolume: function() {
                this.$data.audioObject.volume = this.$data.audioDemoVolume / 100;
            },
            changeDemoPosition: function() {
                if(!this.$data.audioObject.paused) {
                    this.$data.audioObject.pause();
                    this.$data.audioObject.currentTime = this.$data.audioProgress;
                    this.$data.audioObject.play();
                }
            },
            startDemo: function() {
                this.$data.audioObject.play();
                this.$data.isAudioDemoPlaying = true;
                this.$data.audioLength = this.$data.audioObject.duration;
                this.$data.audioInterval = setInterval(() => {
                    this.$data.audioProgress = this.$data.audioObject.currentTime.toFixed();
                }, 500);
            },
            endDemo: function() {
                this.$data.audioObject.pause();
                this.$data.audioObject.currentTime = 0;
                this.$data.isAudioDemoPlaying = false;
                this.$data.audioProgress = 0;
                this.$data.audioLength = 0;
                this.$data.audioURL = null;
                clearInterval(this.$data.audioInterval);
            }
        }
    };
</script>

<style scoped lang="less">
    .audio-demo-player {
        background: #fff;
        box-shadow: 0px 2px 20px rgba(0,0,0,0.15);
        padding: 20px;
        border-radius: 15px;
        width: 450px;
        height: 320px;
        position: absolute;
        z-index: 20;
        bottom: 130px;
        left: 50px;
        display: grid;
        grid-template-rows: 1fr auto;
        grid-gap: 25px;

        & .demo-list {
            overflow-y: auto;

            & .demo-item {
                background: rgba(0,0,0,0.05);
                border-radius: 4px;
                padding: 12px 18px;
                margin-bottom: 6px;
                cursor: pointer;
                transition: 0.2s ease-in-out all;
                display: grid;
                grid-template-columns: 1fr auto;

                &.active {
                    background: rgba(0,0,0,0.2);
                }
                &:not(.active):hover {
                    background: rgba(0,0,0,0.12);
                }
                & .demo-length {
                    font-weight: bold;
                }
            }
        }

        & .controls {
            display: grid;
            grid-template-columns: auto auto 1fr 75px;
            grid-gap: 12px;
            align-items: center;

            & .button {
                width: 48px;
                height: 30px;
                background: rgba(0,0,0,0.6);
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                color: #fff;
                cursor: pointer;
                transition: 0.2s ease-in-out all;

                &.active {
                    background: #000;
                }
                &:not(.active):hover {
                    background: rgba(0,0,0,0.6);
                }
                &.disabled {
                    cursor: not-allowed;
                    opacity: 0.3;
                }
            }
            & .demo-progress-slider {
                appearance: none;
                background: rgba(0,0,0,0.4);
                border-radius: 4px;
                height: 8px;
                overflow: hidden;
                transition: 0.2s ease-in-out all;

                &::-webkit-slider-thumb {
                    appearance: none;
                    height: 8px;
                    width: 8px;
                    background: #000;
                    border-radius: 50%;
                    box-shadow: -104px 0 0 100px #000;
                }
                &:focus {
                    outline: none;
                }
                &.audio-inactive {
                    opacity: 0.3;
                }
            }
            & .demo-volume-slider {
                appearance: none;
                background: rgba(0,0,0,0.4);
                border-radius: 4px;
                height: 8px;
                overflow: hidden;

                &::-webkit-slider-thumb {
                    appearance: none;
                    height: 8px;
                    width: 8px;
                    background: #000;
                    border-radius: 50%;
                    box-shadow: -104px 0 0 100px #000;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .audio-demo-player {
            position: static;
            display: grid;
            width: 95vw;
            height: 400px;
            margin: 30px auto;
        }
    }
</style>
