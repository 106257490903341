<template>
    <div class="back-to-top" v-on:click="backToTop()" :class="windowTop > 150 ? 'visible' : ''"><span class="mdi mdi-chevron-up"></span></div>
</template>

<script>
    export default {
        name: 'BackToTopButton',
        data: function() {
            return {
                windowTop: 0,
            }
        },
        mounted: function() {
            window.addEventListener("scroll", this.checkScroll);
        },
        beforeDestroy: function() {
            window.removeEventListener("scroll", this.checkScroll);
        },
        methods: {
            checkScroll: function() {
                this.$data.windowTop = window.top.scrollY;
            },
            backToTop: function() {
                window.scroll({
                    top: 0, 
                    left: 0, 
                    behavior: 'smooth'
                });
            }
        }
    };
</script>

<style scoped lang="less">
    .back-to-top {
        position: fixed;
        z-index: 100;
        bottom: 50px;
        right: 50px;
        background: #fff;
        box-shadow: 0px 2px 15px rgba(0,0,0,0.3);
        width: 64px;
        height: 64px;
        border-radius: 50%;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out all;

        & .mdi {
            color: #000;
            font-size: 32px;
        }
        &:hover {
            background: #fff;
            box-shadow: 0px 2px 25px rgba(0,0,0,0.45);
            cursor: pointer;
        }

        &.visible {
            opacity: 1;
        }
    }
</style>