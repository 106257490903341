<template>
    <div class="about">
        <div class="box box-aboutme">
            <img src="@/assets/img/avatar.jpeg" />
            <h1>Toni Michael Sattler</h1>
            <p>
                Synchronschauspieler & Musiker<br />
                Geboren – 1987 in Hamburg<br />
                Wohnmöglichkeiten – Berlin, Hamburg<br />
                Sprechalter – 16 bis 40<br />
                Stimmlage – Mittel<br />
                Interessen – Gesang, Gitarre, Hörspiele, Videospiele
            </p>
        </div>
        <div class="box box-more">
            <h1>Weiterbildung</h1>
            <p>
                <strong>Gesangsunterricht:</strong> Markus Hanse<br />
                <strong>Workshops:</strong> Minges Synchrontraining, Toneworx Studiosprechen, iSFF Synchronbuchautor
            </p>
            <p>
                Seit Juli 2015 bin ich mit meinem YouTube-Kanal „Ver-Toni“ aktiv, produziere und engagiere mich regelmäßig für kommerzielle und nicht-kommerzielle Hörspielprojekte. Mittlerweile habe ich an über 150 Projekten als Gastsprecher mitgewirkt und vor kurzem mein 100. Video auf YouTube produziert. Mittlerweile ist dieser Kanal zu meiner persönlichen „Übungsplattform“ umgewandelt worden.
            </p>
            <p>
                Im Jahr 2018 entschied ich mich den Quereinstieg in die Sprecherbranche zu wagen und seit August 2019 bin ich regelmäßig in Studios zugegen.<br />
                Egal ob Hamburg, Berlin, Düsseldorf oder München, mein Engagement spiegelt sich in meiner Bereitschaft wieder.
            </p>
            <p>Frei nach dem Motto: <strong>„Fröhlich, Freundlich, Pünktlich!“</strong></p>
        </div>
    </div>
</template>

<script>
    //import ContactButton from '@/components/ContactForm/ContactButton.vue';

    export default {
        name: 'About',
        components: {
            //ContactButton,
        },
    };
</script>

<style scoped lang="less">
    .about {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 20px;
        padding: 50px 0px;
        padding-top: 25px;
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 95px;

        & .box {
            background: #fff;
            box-shadow: 0px 2px 20px rgba(0,0,0,0.15);
            border-radius: 6px;
            padding: 25px;

            &.box-aboutme {
                display: flex;
                flex-direction: column;
                position: relative;

                & img {
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    display: block;
                    position: relative;
                    align-self: center;
                    margin-bottom: 20px;
                    margin-top: -70px;
                    z-index: 2;
                }
            }
            & h1 {
                margin: 0;
                margin-bottom: 10px;
            }
            & p {
                margin-top: 5px;
                line-height: 1.5;
                color: rgba(0,0,0,0.6);

                & strong {
                    color: #000;
                    font-weight: 600;
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .about {
            grid-template-columns: 1fr 2fr;
            width: 95vw;
        }
    }

    @media screen and (max-width: 1000px) {
        .about {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media screen and (max-width: 800px) {
        .about {
            grid-template-columns: 1fr;
        }
    }
</style>
