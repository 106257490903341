<template>
    <div class="faqs">
        <header>FAQs</header>
        <div class="faq-list">
            <FAQItem v-for="faqItem in faqItems" v-bind="faqItem" v-bind:key="faqItem.id" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';
    
    import FAQItem from '@/components/FAQs/FAQItem.vue';

    export default {
        name: 'FAQs',
        data: function() {
            return {
                vfapi: null,
                faqItems: [],
            }
        },
        components: {
            FAQItem,
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            this.$data.vfapi.getItems('faq_item', '*').then(data => {
                this.$data.faqItems = data;
            });
        }
    };
</script>

<style scoped lang="less">
    .faqs {
        padding: 50px 0px;
        padding-top: 25px;
        max-width: 1200px;
        margin: 0 auto;

        & header {
            font-weight: bold;
            font-size: 32px;
            margin-bottom: 15px;
        }
        & .faq-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 10px;
        }
    }

    @media screen and (max-width: 1250px) {
        .faqs {
            width: 95vw;
            margin-top: 0px;

            & .faq-list {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .faqs {
            margin-top: 0px;

            & .faq-list {
                grid-template-columns: 1fr;
            }
        }
    }
</style>
