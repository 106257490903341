<template>
    <div class="features">
        <!-- 
        <div class="feature">
            <div class="title">Heimaufnahmen</div>
            <div class="box">
                Ich besitze ein sehr gutes Heimstudio, mit dem ich über SessionLink, SourceConnect, Skype, Zoom, Teams etc. erreichbar bin.
            </div>
        </div>
        <div class="feature">
            <div class="title">Studioaufnahmen</div>
            <div class="box">
                Werde ich im Studio gebraucht, bin ich mit einer Voranmeldung von 24 Stunden auch vor Ort erreichbar.
            </div>
        </div>
        <div class="feature">
            <div class="title">Dialogbuch</div>
            <div class="box">
                Anfragen für Dialogbücher sind auch möglich. Eine Auswahl meiner beteiligten Projekte finden Sie in meinen Referenzen.
            </div>
        </div>
        -->
    </div>
</template>

<script>
    export default {
        name: 'Features'
    };
</script>

<style scoped lang="less">
    .features {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        //padding: 50px 0px;
        //padding-top: 25px;
        max-width: 1200px;
        margin: 0 auto;

        & .feature {
            display: grid;
            grid-template-rows: auto 1fr;
            grid-gap: 10px;

            & .title {
                font-weight: 700;
                font-size: 18px;
                text-align: center;
                letter-spacing: 0.2em;
                text-transform: uppercase;
            }
            & .box {
                background: #fff;
                box-shadow: 0px 2px 20px rgba(0,0,0,0.15);
                border-radius: 6px;
                padding: 25px;
                line-height: 1.5;;
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .features {
            grid-template-columns: 1fr;
            padding: 25px 50px;
        }
    }
</style>
