<template>
    <div class="contact-form" :class="isActive ? 'active' : ''" v-on:click.self="closeModal()">
        <div class="contact-box">
            <div class="contact-header">Kontakt</div>
            <div class="options">
                <div class="item">
                    <div class="header">Per E-Mail</div>
                    <div class="text">kontakt@toni-sattler.de</div>
                </div>
                <div class="item">
                    <div class="header">Per Telefon</div>
                    <div class="text">+49 157 52574433</div>
                </div>
            </div>
            <div class="actions">
                <div class="button" v-on:click="closeModal()">Schließen</div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';

    export default {
        name: 'ContactForm',
        data: function() {
            return {
                vfapi: null,
                isActive: false,
                formName: "",
                formMail: "",
                formMessage: ""
            }
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            document.addEventListener('keyup', this.computeESCClose);

            // Global Modal Open Listener
            this.$root.$on('openContactForm', () => {
                this.openModal();
            });
        },
        beforeDestroy: function() {
            document.removeEventListener('keyup', this.computeESCClose);
        },
        methods: {
            computeESCClose: function(e) {
                if(e.key == "Escape") {
                    this.closeModal();
                }
            },
            openModal: function() {
                this.$data.isActive = true;
            },
            closeModal: function() {
                this.$data.isActive = false;
            },
            sendForm: function() {
                // Don't do anything if name, mail or message are empty
                if(this.$data.formName == "" || this.$data.formMail == "" || this.$data.formMessage == "") return;

                // Send API Call
                this.$data.vfapi.postItem('contact_entries', {
                    form_name: this.$data.formName,
                    form_mail: this.$data.formMail,
                    form_message: this.$data.formMessage
                }).then(data => {
                    // Cleanup After Sending
                    this.$data.formName = "";
                    this.$data.formMail = "";
                    this.$data.formMessage = "";

                    // Close Modal
                    this.closeModal();
                });
            }
        }
    };
</script>

<style scoped lang="less">
    .contact-form {
        background: rgba(0,0,0,0.7);
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: none;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out all;
        cursor: pointer;

        & .contact-box {
            background: #fff;
            width: 500px;
            padding: 25px;
            border-radius: 6px;
            cursor: default;
            position: relative;

            & .contact-header {
                color: #fff;
                font-size: 32px;
                font-weight: 600;
                position: absolute;
                top: -50px;
                left: 0px;
                right: 0px;
                text-align: center;
            }

            & .options {
                display: grid;
                grid-gap: 25px;

                & .item {
                    & .header {
                        font-weight: bold;
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                }
            }

            & .actions {
                display: flex;
                grid-gap: 25px;
                margin-top: 25px;
                justify-content: center;

                & .button {
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: bold;
                    background: #000;
                    color: #fff;
                    padding: 8px 40px;
                    border-radius: 100px;
                    transition: 0.2s ease-in-out all;
                    text-align: center;

                    &:not(.button-disabled):hover {
                        opacity: 0.6;
                        cursor: pointer;
                    }
                    &:not(.button-disabled):active {
                        opacity: 0.8;
                    }
                    &.button-disabled {
                        opacity: 0.2;
                        cursor: not-allowed;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        &.active {
            display: flex;
            opacity: 1;
        }
    }

    @media screen and (max-width: 800px) {
        &.contact-form {
            & .contact-box {
                grid-template-columns: 1fr;
                overflow-y: auto;
                max-height: 90%;

                & .sidebar {
                    height: 200px;
                    margin-top: -50px;
                    margin-bottom: 25px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                & .button {
                    position: static;
                    width: auto;
                    justify-self: center;
                    margin-left: 0px;
                    margin-bottom: 15px;
                }
            }
        }
    }
</style>
