<template>
    <footer>
        <span>&copy; 2020 &dash; Toni Michael Sattler</span>

        <router-link :to="{ name: 'LegalInformation' }">Impressum & Datenschutz</router-link>

        <ContactButton />
    </footer>
</template>

<script>
    import ContactButton from '@/components/ContactForm/ContactButton.vue';

    export default {
        name: 'Footer',
        components: {
            ContactButton,
        },
    };
</script>

<style scoped lang="less">
    footer {
        padding: 50px;
        margin-top: 25px;
        background: #f1f1f1;
        text-align: center;

        & span {
            font-size: 18px;
            display: block;
            margin-bottom: 5px;
        }
        & a {
            color: rgba(0,0,0,0.6);
            font-size: 12px;
            text-decoration: none;
            display: block;
            margin-bottom: 20px;
            transition: 0.15s ease color;

            &:hover {
                color: #000;
            }
        }
        & .button {
            display: inline-block;
        }
    }
</style>
