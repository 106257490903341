<template>
    <div class="faq-overlay" :class="isActive ? 'active' : ''" v-on:click.self="closeModal()">
        <div class="close-button" v-on:click="closeModal()"><span class="mdi mdi-close"></span></div>
        <div class="overlay-box" v-if="!isLoading">
            <div class="question">{{ faqQuestion }}</div>
            <div class="answer" v-html="faqAnswer"></div>
        </div>
        <div class="loading-box" v-if="isLoading">
            <div class="loading-icon"></div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';

    export default {
        name: 'ContactForm',
        data: function() {
            return {
                vfapi: null,
                isActive: false,
                isLoading: true,
                faqId: 0,
                faqQuestion: "",
                faqAnswer: ""
            }
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            document.addEventListener('keyup', this.computeESCClose);

            // Global Modal Open Listener
            this.$root.$on('openFAQOverlay', (faqId) => {
                this.openModal();

                this.$data.isLoading = true;
                this.$data.faqQuestion = "";
                this.$data.faqAnswer = "";

                this.$data.vfapi.getItems('faq_item/' + faqId, '*').then(data => {
                    this.$data.faqQuestion = data.question;
                    this.$data.faqAnswer = data.answer;
                    this.$data.isLoading = false;
                });
            });
        },
        beforeDestroy: function() {
            document.removeEventListener('keyup', this.computeESCClose);
        },
        methods: {
            computeESCClose: function(e) {
                if(e.key == "Escape") {
                    this.closeModal();
                }
            },
            openModal: function() {
                this.$data.isActive = true;
            },
            closeModal: function() {
                this.$data.isActive = false;
            },
        }
    };
</script>

<style scoped lang="less">
    .faq-overlay {
        background: rgba(0,0,0,0.7);
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: none;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out all;
        cursor: pointer;

        & .close-button {
            position: absolute;
            z-index: 100;
            right: 50px;
            top: 50px;
            background: #fff;
            box-shadow: 0px 2px 15px rgba(0,0,0,0.3);
            width: 64px;
            height: 64px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-in-out all;

            & .mdi {
                color: #000;
                font-size: 32px;
            }
            &:hover {
                background: #fff;
                box-shadow: 0px 2px 25px rgba(0,0,0,0.45);
                cursor: pointer;
            }

            &.visible {
                opacity: 1;
            }
        }

        & .overlay-box {
            background: #fff;
            width: 90%;
            max-width: 700px;
            max-height: 90%;
            overflow-y: scroll;
            padding: 25px;
            border-radius: 6px;
            cursor: default;
            position: relative;

            & .question {
                font-weight: bold;
                font-size: 22px;
            }
            & .answer {
                line-height: 1.5em;
            }
        }

        & .loading-box {
            background: #fff;
            width: 90%;
            max-width: 700px;
            height: 200px;
            padding: 25px;
            border-radius: 6px;
            cursor: default;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            & .loading-icon {
                width: 50px;
                height: 50px;
                background: rgba(0,0,0,0.15);
                border-radius: 50%;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    background: transparent;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border-top: 12px solid rgba(0,0,0,0.6);
                    display: block;
                    animation: loadingAnim 0.4s infinite linear;
                }
                &:after {
                    content: "";
                    position: absolute;
                    background: #fff;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    left: 5px;
                    top: 5px;
                    display: block;
                }
            }
        }
        &.active {
            display: flex;
            opacity: 1;
        }
    }

    @keyframes loadingAnim {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @media screen and (max-width: 700px) {
        .faq-overlay {
            position: fixed;

            & .overlay-box {
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                width: 100vw;
                height: 100vh;
                border-radius: 0px;
                max-width: 100vw;
                max-height: 100vh;
                padding-bottom: 120px;
            }
            & .loading-box {
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                width: 100vw;
                height: 100vh;
                border-radius: 0px;
                max-width: 100vw;
                max-height: 100vh;
            }

            & .close-button {
                top: auto;
                bottom: 25px;
                right: 25px;
            }
        }
    }
</style>
