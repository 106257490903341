<template>
    <div class="work-roles">
        <div class="work-roles-selection">
            <div class="selection-item" :class="allTypesSelected ? 'active': ''" v-on:click="selectAll()">Alle</div>
            <div class="selection-item" v-for="type in types" v-bind:key="type.type" :class="currentSelectedType == type.type ? 'active': ''" v-on:click="selectType(type.type)"><span :class="'mdi mdi-' + type.icon"></span> {{ type.title }}</div>
        </div>

        <div class="work-roles-list">
            <WorkRoleItem v-for="workRole in allWorkRoles" v-bind="workRole" v-bind:key="workRole.id" v-bind:isActive="currentSelectedType == workRole.role_type || allTypesSelected" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';
    
    import WorkRoleItem from '@/components/WorkRoles/WorkRoleItem.vue';

    export default {
        name: 'NewsItem',
        data: function() {
            return {
                vfapi: null,
                allWorkRoles: [],
                allTypesSelected: true,
                currentSelectedType: "",
                types: [
                    {title: "Synchron", type: "dubbing", icon: "account-voice"},
                    {title: "Voice Over", type: "voiceover", icon: "microphone"},
                    {title: "Games", type: "games", icon: "controller-classic"},
                    {title: "Hörspiel", type: "audiobook", icon: "headphones"},
                    {title: "Dialogbuch", type: "dialogue", icon: "book-open-page-variant"},
                    {title: "Werbung", type: "ad", icon: "cart-outline"},
                    {title: "Anime & Cartoon", type: "animecartoon", icon: "movie-open"},
                    {title: "Realfilm", type: "movies", icon: "video-vintage"},
                ]
            }
        },
        components: {
            WorkRoleItem,
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            this.$data.vfapi.getItems('work_role', '*,cover_image.data.url').then(data => {
                this.$data.allWorkRoles = data;
                this.$data.allTypesSelected = true;
                this.$data.currentSelectedType = "";
            });
        },
        methods: {
            selectAll: function() {
                this.$data.allTypesSelected = true;
                this.$data.currentSelectedType = "";
            },
            selectType: function(newType) {
                this.$data.allTypesSelected = false;
                this.$data.currentSelectedType = newType;
            }
        }
    };
</script>

<style scoped lang="less">
    .work-roles {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: 10px;
        padding: 50px 0px;
        padding-top: 25px;
        max-width: 1200px;
        margin: 0 auto;

        & .work-roles-selection {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 800px;
            margin: 0 auto;

            & .selection-item {
                padding: 5px 25px;
                border-radius: 100px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.1em;
                margin-right: 15px;
                margin-bottom: 10px;
                transition: 0.15s ease-in-out all;
                display: flex;
                align-items: center;

                & .mdi {
                    font-size: 22px;
                    margin-right: 5px;
                }

                &.active {
                    background: rgba(0,0,0,0.17);
                }
                &:hover {
                    background: rgba(0,0,0,0.07);
                    cursor: pointer;
                }
            }
        }
        & .work-roles-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
        }
    }

    @media screen and (max-width: 1250px) {
        .work-roles {
            width: 95vw;

            & .work-roles-selection {
                flex-wrap: wrap;
                align-items: center;
            }

            & .work-roles-list {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .work-roles {
            & .work-roles-list {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
</style>
