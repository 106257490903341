import Vue from 'vue';
import VueRouter from 'vue-router';
import Base from '../views/Base.vue';
import PageIndex from '../views/PageIndex.vue';
import PageLegalInformation from '../views/PageLegalInformation.vue';
import PageVita from '../views/PageVita.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Base',
        component: Base,
        children: [
            {
                path: '/',
                name: 'Index',
                component: PageIndex,
            },
            {
                path: '/legal-information',
                name: 'LegalInformation',
                component: PageLegalInformation,
            },
        ],
    },
    {
        path: '/vita',
        name: 'Vita',
        component: PageVita,
    }
];

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
