<template>
    <div class="page">
        <Header />
        <router-view/>
        <Footer />

        <BackToTopButton />

        <ContactForm />

        <FAQOverlay />
    </div>
</template>

<script>
    import Header from '@/components/Base/Header.vue';
    import BackToTopButton from '@/components/Base/BackToTopButton.vue';
    import Footer from '@/components/Base/Footer.vue';
    import ContactForm from '@/components/ContactForm/ContactForm.vue';
    import FAQOverlay from '@/components/FAQs/FAQOverlay.vue';

    export default {
        name: 'Base',
        components: {
            Header,
            BackToTopButton,
            Footer,
            ContactForm,
            FAQOverlay,
        },
        mounted: function() {
        },
        methods: {
        }
    };
</script>

<style lang="less">
    body {
        background: #ffffff;
        color: #000000;
        width: 100%;
        height: 100vh;
        user-select: none;
    }
</style>