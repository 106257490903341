<template>
    <div class="button" v-on:click="openContactFormModal()">
        Kontakt
    </div>
</template>

<script>
    export default {
        name: 'ContactButton',
        methods: {
            openContactFormModal: function() {
                this.$root.$emit('openContactForm');
            }
        }
    };
</script>

<style scoped lang="less">
    .button {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        background: #fff;
        box-shadow: 0px 2px 20px rgba(0,0,0,0.15);
        color: #222;
        padding: 8px 25px;
        border-radius: 100px;
        transition: 0.2s ease-in-out all;

        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
        &:active {
            opacity: 0.8;
        }
    }

    @media screen and (max-width: 700px) {
        & .button {
            padding: 4px 10px;
        }
    }
</style>
