<template>
    <div class="faq-item" v-on:click="showAnswer()">
        <div class="question">{{ question }}</div>
        <div class="answer" v-html="answer"></div>
        <div class="shade">Mehr Lesen</div>
    </div>
</template>

<script>
    export default {
        name: 'WorkRoleItem',
        props: [
            'id',
            'question',
            'answer'
        ],
        methods: {
            showAnswer: function() {
                this.$root.$emit('openFAQOverlay', this.$props.id);
            }
        }
    };
</script>

<style scoped lang="less">
    .faq-item {
        background: #fff;
        box-shadow: 0px 2px 15px rgba(0,0,0,0.15);
        border-radius: 6px;
        padding: 25px;
        overflow: hidden;
        position: relative;
        height: 250px;
        transition: 0.2s ease-in-out all;

        & .question {
            font-size: 18px;
            line-height: 1.5;
            font-weight: bold;
        }
        & .answer {
            opacity: 0.6;
            line-height: 1.5;
        }
        & .shade {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 50%;
            background: linear-gradient(0deg, rgba(255,255,255,1), transparent);
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.15em;
            padding: 25px;
            text-transform: uppercase;
        }

        &:hover {
            box-shadow: 0px 2px 30px rgba(0,0,0,0.25);
            transform: scale(1.1);
            z-index: 2;
            cursor: pointer;
        }
    }
</style>
