<template>
    <div class="news-items">
        <div class="box box-bookings">
            <div class="bookings-title">Synchron-Buchungen</div>
            <a href="https://www.synchronstar.de/intern/detail/toni-michael-sattler/666" target="_blank" class="booking-item">
                <img src="@/assets/img/synchronstar.png" />
                <div class="booking-text">Für Anfragen aus Berlin</div>
            </a>
            <a href="mailto:kontakt@toni-sattler.de" class="booking-item">
                <span class="mdi mdi-mailbox-open-outline"></span>
                <div class="booking-text">Für Anfragen aus Hamburg</div>
            </a>
        </div>

        <NewsItem v-for="newsItem in newsItems" v-bind="newsItem" v-bind:key="newsItem.id" />
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';
    
    import NewsItem from '@/components/NewsItems/NewsItem.vue';

    export default {
        name: 'NewsItems',
        data: function() {
            return {
                vfapi: null,
                newsItems: [],
            }
        },
        components: {
            NewsItem,
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            this.$data.vfapi.getItems('news_item', '*,cover_image.data.url').then(data => {
                this.$data.newsItems = data;
            });
        }
    };
</script>

<style scoped lang="less">
    .news-items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        padding: 50px 0px;
        padding-top: 0px;
        max-width: 1200px;
        width: 95vw;
        margin: 0 auto;
        margin-top: -50px;
        position: relative;
        z-index: 2;

        & .box {
            background: #fff;
            box-shadow: 0px 2px 20px rgba(0,0,0,0.15);
            border-radius: 6px;

            &.box-bookings {
                padding: 25px;
                display: grid;
                grid-template-rows: auto 1fr 1fr;
                grid-gap: 15px;

                & .bookings-title {
                    font-size: 22px;
                    font-weight: 600;
                }
                & .booking-item {
                    background: rgba(0,0,0,0.07);
                    border-radius: 6px;
                    color: #000;
                    text-decoration: none;
                    display: grid;
                    grid-template-rows: 1fr auto;
                    grid-gap: 10px;
                    padding: 15px;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    transition: 0.2s ease-in-out all;

                    & img {
                        max-height: 40px;
                        justify-self: center;
                    }
                    & .mdi {
                        font-size: 44px;
                    }

                    &:hover {
                        background: rgba(0,0,0,0.14);
                        cursor: pointer;
                    }
                    &:active {
                        background: rgba(0,0,0,0.21);
                    }
                }
            }
            &.box-newsitem {
                transition: 0.2s ease-in-out all;

                & .newsitem-cover {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    height: 220px;
                    background-position: center;
                    background-size: cover;
                    position: relative;

                    & img {
                        position: absolute;
                        bottom: -1px;
                        left: 0px;
                        right: 0px;
                        height: 50px;
                        width: 100%;
                    }
                }
                & .newsitem-title {
                    text-align: center;
                    font-size: 22px;
                    font-weight: 600;
                    padding: 25px;
                    transform: translateY(-10px);
                }

                &:hover {
                    transform: translateY(-20px) skew(0deg, -2deg);
                    cursor: pointer;
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        & .news-items {
            grid-template-columns: 1fr 1fr;
            margin-top: 0px;

            & .box-bookings {
                grid-column: 1 / span 2;
                margin-bottom: 25px;
            }
        }
    }

    @media screen and (max-width: 800px) {
        & .news-items {
            grid-template-columns: 1fr;

            & .box-bookings {
                grid-column: 1;
                margin-bottom: 0px;
            }
        }
    }
</style>
